<template>
    <div
        class="d-flex flex-column justify-center align-center fill-height px-2"
    >
        <v-card
            class="mx-auto py-8"
            :class="$vuetify.breakpoint.xs ? 'px-2' : 'px-8'"
            width="378"
            elevation="0"
            rounded="lg"
        >
            <div class="d-flex flex-column align-center mt-5">
                <v-img
                    width="102"
                    height="101"
                    src="@/assets/img/newLogoEtiqueta.svg"
                ></v-img>
            </div>

            <v-card-text class="mt-12">
                <LoginForm />
            </v-card-text>
        </v-card>

        <ArticleCopyright />
    </div>
</template>

<script>
import LoginForm from "@/components/forms/LoginForm";
import ArticleCopyright from "@/components/articles/ArticleCopyright";

export default {
    name: "Login",

    components: {
        LoginForm,
        ArticleCopyright,
    },

    data: () => ({}),
};
</script>

<style lang="scss" scoped></style>
