<template>
  <v-navigation-drawer app width="300" v-model="controlMobileOpen">
    <MenuNavHeader />
    <MenuNavModules :isUserAdmin="isUserAdmin" :isUserSeller="isUserSeller" />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MenuNavHeader from './navbar/MenuNavHeader'
import MenuNavModules from './navbar/MenuNavModules'

export default {
  name: 'MenuNav',

  components: {
    MenuNavHeader,
    MenuNavModules
  },

  props: {
    isUserAdmin: Boolean,
    isUserSeller: Boolean
  },

  data: () => ({}),

  computed: {
    ...mapGetters({
      isMobileOpen: 'mobileOpen'
    }),
    controlMobileOpen: {
      get () {
        return this.isMobileOpen
      },
      set (value) {
        this.handleMobileMenu(value)
      }
    }
  },
  methods: {
    ...mapActions(['handleMobileMenu'])
  }
}
</script>

<style scoped>
</style>
