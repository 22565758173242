<template>
  <div v-if="item!=null">
    <CardState :item="item" />
    <SaleAlert v-if="item!=null"
      :color="getColor({ state: item.order_status, shipping: item.shipping_line })"
      :state="item.order_status.name"
      :enablePersonalize="saleAlertCustom"
      :item="item"
      @showPersonalization="dialog = true">
    </SaleAlert>
    <AppTable
      :dataTable="dataTable"
      :headers="headers"
      :getColor="getColor"
      :elevation="'elevation-0'"
      :expanded="expanded"
      @handleExpandedItem="handleExpandedItem"
    >
      <template v-slot:iconInsideColumn="slotProps">
        <component :is="setIcon(slotProps.item.id_sales_channel)" class="mr-4"/>
      </template>

      <template v-slot:columnExpandTable="slotProps">
        <ColumnExpand
          :item="slotProps.item"
          :isExpanded="slotProps.isExpanded"
          :expand="slotProps.expand"
        />
      </template>

      <template v-slot:expandedItemTable="slotProps">
        <ExpandedItem
          :item="slotProps.item"
          :headers="slotProps.headers"
          :expandHeaders="expandHeaders"
        />
      </template>
    </AppTable>
    <CenterCards :item="item"></CenterCards>
    <ViewCardDetails :boxInputValues="boxInputValues" :itemProp="item"></ViewCardDetails>
    <PopupPersonalization :dialog="dialog" :item="item" @closeDialog="dialog = false" :edit="true"></PopupPersonalization>
    <PopupText :dialog="popupTextDialog" :item="item" @closeDialog="popupTextDialog = false" />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import CardState from '../preview/CardState.vue'
import SaleAlert from '../preview/Alert.vue'
import CenterCards from '../preview/centerCards.vue'
import ViewCardDetails from '../preview/ViewCardDetail.vue'
import AppTable from '@/components/views/expandTable/AppTable.vue'
import ColumnExpand from '@/components/views/expandTable/tableComponents/ColumnExpand.vue'
import ExpandedItem from '@/components/views/expandTable/tableComponents/ExpandedItem.vue'
import stateDialog from '../preview/StateDialog.vue'
import PopupPersonalization from './PopupPersonalizacion.vue'
import PopupText from './PopupText'
import { getOrderIdEncripted } from '@/services/orders.js'
import { MeliIcon, WooIcon } from '@/assets/icons'
export default {
  mounted () {
    this.initialize()
  },
  components: {
    CardState,
    SaleAlert,
    AppTable,
    CenterCards,
    ViewCardDetails,
    ColumnExpand,
    ExpandedItem,
    MeliIcon,
    WooIcon,
    stateDialog,
    PopupPersonalization,
    PopupText
  },
  data: () => ({
    expanded: [],
    singleExpand: false,
    expandHeaders: [
      { text: 'producto', value: 'product_name', align: 'left' },
      { text: 'Nombre', value: 'name', align: 'left' },
      { text: 'Apellido', value: 'last_name', align: 'left' },
      { text: 'Personaje', value: 'icon', align: 'left' },
      { text: 'Color', value: 'color', align: 'left' },
      { text: 'Cantidad', value: 'quantity', align: 'center' },
      { text: 'Precio', value: 'unit_amount', align: 'center' },
      { text: 'Total', value: 'product_total', align: 'center' }
    ],
    headers: [
      { text: 'Nro', value: 'number', align: 'center' },
      { text: 'Correo', value: 'client.email' },
      { text: 'Fecha', value: 'created_at' },
      { text: 'Estado', value: 'state' },
      { text: 'Total', value: 'total_amount' },
      { text: '', value: 'data-table-expand' }
    ],
    dataTable: [],
    boxInputValues: [
      { text: 'Nombre', value: 'name' },
      { text: 'Apellido', value: 'last_name' },
      { text: 'Mail', value: 'email' },
      { text: 'Teléfono', value: '13431431' },
      { text: 'Dirección', value: 'client_address' },
      { text: 'Código Postal', value: 'client_postcode' },
      { text: 'Localidad', value: 'client_city' },
      { text: 'Provincia', value: 'client_Province' }
    ],
    item: null,
    dialog: false,
    popupTextDialog: false,
    saleAlertCustom: false
  }),
  methods: {
    ...mapActions(['handleLoading', 'handleAlert']),

    handleExpandedItem (val) {
      this.expanded = this.dataTable
    },

    async initialize () {
      await this.getSale()
      this.dataTable.push(this.item)
      this.enablePersonalize(this.item)
      this.handleExpandedItem()
    },

    formatData (data) {
      data.products_order.forEach(e => {
        e.name = e.product.customizable ? e.name : 'no personalizable'
        e.last_name = e.product.customizable ? e.last_name : 'no personalizable'
        e.icon = e.product.customizable ? e.icon.replace(' ', '-').toLowerCase().trim() : 'no personalizable'
        e.color = e.product.customizable ? e.color : 'no personalizable'
        e.product_name = e.product.name
        e.product_total = parseFloat(e.unit_amount) * parseFloat(e.quantity)
      })
      this.item = data
    },

    enablePersonalize (sale) {
      sale.products_order.forEach(e => {
        if (e.orderCustomizable) {
          this.saleAlertCustom = true
          return true
        }
      })
      return false
    },

    async getSale () {
      try {
        this.handleLoading(true)
        const order = this.$route.params.id
        // const res = await getOrder(order)
        const res = await getOrderIdEncripted(order)
        this.formatData(res.data.order)
      } catch (error) {
        this.handleMessageErrorApi(error)
      } finally {
        this.handleLoading(false)
      }
    },

    handleMessageErrorApi (error) {
      this.alertColor = 'error'
      if (error.status === 422) {
        const firstError = Object.keys(error.data.errors)[0]
        this.alertMessage = error.data.errors[firstError][0]
      } else {
        this.alertMessage = error.status
      }
      this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
    },

    getColor (value) {
      let result = ''
      if (value.state.id === 5) {
        result = (value.shipping.includes('Retiro oficina') || value.shipping.includes('Retiro por local')) ? 'Pedido listo para retirar' : 'Pedido listo para despachar'
      } else {
        result = value.state.name
      }
      const colors = {
        Aprobado: 'rgba(232, 0, 237, 1)',
        'En produccion': 'rgba(255, 114, 0, 0.35)',
        'Pedido listo para retirar': 'rgba(0, 237, 137, 1)',
        'Pedido listo para despachar': 'rgba(0, 150, 137, 1)',
        Cancelado: 'rgba(237, 71, 0, 1)'
      }
      return colors[result] || 'rgba(255, 114, 0, 0.35)'
    },

    setIcon (value) {
      const icon = {
        Meli: 'MeliIcon',
        Woo: 'WooIcon'
      }
      value = value === 2 ? 'Woo' : 'Meli'
      return icon[value] || 'div'
    }
  },
  watch: {
    item: {
      deep: true,
      handler () {
        this.popupTextDialog = ([4, 5, 8].includes(this.item.order_status?.id))
      }
    }
  }
}
</script>
