 import Vue from 'vue'
 import VueRouter from 'vue-router'

 Vue.use(VueRouter)

 const isLoggedIn = () => {
     const authToken = localStorage.getItem('userInfoToken')
     const parseToken = JSON.parse(authToken)
     return !!parseToken.token
 }

 // console.log('ilgin', JSON.parse(localStorage.getItem('userInfoToken')).user.roles.some(r => r.id === 1))

 const routes = [{
         path: '/login',
         name: 'Login',
         meta: {
             allowAllUsers: true
         },
         component: () =>
             import ('../views/Login.vue')
     },
     {
         path: '/dashboard',
         name: 'Dashboard',
         component: () =>
             import ('../views/Dashboard.vue')
     },
     {
         path: '/mayorista',
         name: 'Mayorista',
         component: () =>
             import ('../views/Mayorista.vue')
     },
     {
         path: '/bothProducts',
         name: 'Both products',
         component: () =>
             import ('../views/BothProducts.vue')
     },
     {
         path: '/fares',
         name: 'Fares',
         component: () =>
             import ('../views/Fares.vue')
     },
     {
         path: '/users',
         name: 'Users',
         component: () =>
             import ('../views/Users.vue')
     },
     {
         path: '/orders',
         name: 'Orders',
         component: () =>
             import ('../views/Orders.vue')
     },
     {
         path: '/sales',
         name: 'Sales',
         component: () =>
             import ('../views/Sales.vue')
     },
     {
         path: '/products',
         name: 'Products',
         component: () =>
             import ('../views/Products.vue')
     },
     {
         path: '/preview/:order',
         name: 'Vista Previa',
         component: () =>
             import ('../views/Preview.vue')
     },
     {
         path: '/order/:id',
         name: 'Personalizar Pedido',
         meta: {
             allowAllUsers: true
         },
         component: () =>
             import ('../views/PersonalizeSale.vue')
     }
 ]

 const router = new VueRouter({
     mode: 'history',
     base: process.env.BASE_URL,
     routes
 })

 router.beforeEach((to, from, next) => {
     if (to.path === '/' && isLoggedIn()) {
         next({ name: 'Sales' })
     } else if (to.name === 'Login' && isLoggedIn()) {
         next({ name: 'Sales' })
     } else if (!to.meta.allowAllUsers && !isLoggedIn()) {
         next({
             name: 'Login'
         })
     } else {
         next()
     }
 })

 export default router