<template>
    <td :colspan="headers.length">
        <div class="grid-container">
            <div class="grid">
                <span
                    v-for="(i, index) in expandHeaders"
                    :class="`caption text-secondary text-${i.align}`"
                    :key="index"
                    >{{ i.text }}</span
                >
            </div>

            <div
                class="text-left grid"
                v-for="(element, index) in item.products_order"
                :key="index"
            >
                <span
                    v-for="(i, index) in expandHeaders"
                    :key="index"
                    :class="`caption text-secondary text-${i.align}`"
                >
                    <span
                        v-if="
                            (element.product.customizable === 0 ||
                                index !== 4) &&
                            !quantityWithChip(i, element)
                        "
                        :style="`font-family: Roboto; font-size: 0.75rem; font-weight: ${
                            i.text === 'comentario' &&
                            !element[i.value].includes('Dato extra')
                                ? '700'
                                : '400'
                        }`"
                    >
                        {{ element[i.value] }}
                    </span>
                    <v-icon
                        v-if="
                            index === 4 &&
                            element.product.customizable === 1 &&
                            element[i.value] !== ''
                        "
                        :color="element[i.value]"
                        dense
                        :title="element[i.value]"
                    >
                        {{ mdicircle }}
                    </v-icon>
                    <v-chip
                        color="secondary"
                        v-if="quantityWithChip(i, element)"
                    >
                        {{ element[i.value] }}
                    </v-chip>
                </span>
            </div>
        </div>
        <!--  <td :colspan="headers.length">
        <table style="width: 100%" class="mt-2">
          <tr style="padding-top: 12px; padding-bottom: 12px; text-align: left">
            <th
              v-for="(i, index) in expandHeaders"
              :class='`caption text&#45;&#45;secondary text-${i.align}`'
              :key="index"
            >
              {{ i.text }}
            </th>
          </tr>
          <tr v-for="(element, index) in item.products_order" :key="index">
            <td :class='`caption text-${i.align}`' v-for="(i, index) in expandHeaders" :key="index">
              <span v-if="element.product.customizable===0 || index!==4" style="font-family:Roboto; font-size:0.75rem; font-weight: 400;">
                {{element[i.value]}}
              </span>
              <v-icon v-if="index===4 && element.product.customizable===1 && element[i.value] !== ''" :color="element[i.value]" dense :title="element[i.value]">
                {{mdicircle}}
              </v-icon>
            </td>
          </tr>
        </table>-->

        <div v-if="!isSaleTable" class="border-dashed my-2" />
        <ExpandedItemComentary
            title="Comentario"
            :commentary="item.commentary"
            v-if="!isSaleTable"
        />

        <div
            class="border-dashed my-2"
            v-if="item.customer_note !== '' && !isSaleTable"
        />
        <ExpandedItemComentary
            title="Comentario cliente"
            :commentary="item.customer_note"
            v-if="item.customer_note !== '' && !isSaleTable"
        />
    </td>
</template>

<script>
import { mdiCheckboxBlankCircle } from "@mdi/js";
import ExpandedItemComentary from "./ExpandedItemComentary";

export default {
    name: "ExpandedItem",
    components: {
        ExpandedItemComentary,
    },
    props: {
        item: Object,
        headers: Array,
        expandHeaders: {
            type: Array,
            default() {
                return [
                    {
                        text: "Producto",
                        value: "product_name",
                        align: "left",
                    },
                    {
                        text: "Nombre",
                        value: "name",
                        align: "left",
                    },
                    {
                        text: "Personaje",
                        value: "character",
                        align: "left",
                    },
                    {
                        text: "Color",
                        value: "color",
                        align: "left",
                    },
                    {
                        text: "Cantidad",
                        value: "quantity",
                        align: "right",
                    },
                    {
                        text: "Precio",
                        value: "unit_amount",
                        align: "right",
                    },
                    {
                        text: "Total",
                        value: "total",
                        align: "right",
                    },
                ];
            },
        },
        isSaleTable: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({ mdicircle: mdiCheckboxBlankCircle }),
    methods: {
        quantityWithChip(i, el) {
            return i.value === "quantity" && el[i.value] > 1;
        },
    },
};
</script>

<style lang="scss" scoped>
.border-dashed {
    width: 100%;
    height: 2px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E0E0E0FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.grid {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 0.5fr 1.5fr 2fr 0.5fr 0.5fr;
    grid-gap: 20px;
    margin: 10px 0;
}
</style>
