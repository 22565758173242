var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid"},_vm._l((_vm.expandHeaders),function(i,index){return _c('span',{key:index,class:`caption text-secondary text-${i.align}`},[_vm._v(_vm._s(i.text))])}),0),_vm._l((_vm.item.products_order),function(element,index){return _c('div',{key:index,staticClass:"text-left grid"},_vm._l((_vm.expandHeaders),function(i,index){return _c('span',{key:index,class:`caption text-secondary text-${i.align}`},[(
                        (element.product.customizable === 0 ||
                            index !== 4) &&
                        !_vm.quantityWithChip(i, element)
                    )?_c('span',{style:(`font-family: Roboto; font-size: 0.75rem; font-weight: ${
                        i.text === 'comentario' &&
                        !element[i.value].includes('Dato extra')
                            ? '700'
                            : '400'
                    }`)},[_vm._v(" "+_vm._s(element[i.value])+" ")]):_vm._e(),(
                        index === 4 &&
                        element.product.customizable === 1 &&
                        element[i.value] !== ''
                    )?_c('v-icon',{attrs:{"color":element[i.value],"dense":"","title":element[i.value]}},[_vm._v(" "+_vm._s(_vm.mdicircle)+" ")]):_vm._e(),(_vm.quantityWithChip(i, element))?_c('v-chip',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(element[i.value])+" ")]):_vm._e()],1)}),0)})],2),(!_vm.isSaleTable)?_c('div',{staticClass:"border-dashed my-2"}):_vm._e(),(!_vm.isSaleTable)?_c('ExpandedItemComentary',{attrs:{"title":"Comentario","commentary":_vm.item.commentary}}):_vm._e(),(_vm.item.customer_note !== '' && !_vm.isSaleTable)?_c('div',{staticClass:"border-dashed my-2"}):_vm._e(),(_vm.item.customer_note !== '' && !_vm.isSaleTable)?_c('ExpandedItemComentary',{attrs:{"title":"Comentario cliente","commentary":_vm.item.customer_note}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }