<template>
    <v-list flat class="px-3" nav>
        <v-list-item-group color="primary">
            <v-list-item
                v-for="(item, i) in items"
                :key="i"
                :to="item.pathName"
                @click="$store.state.textSearch = ''"
            >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="text-left font-weight-medium">{{
                        item.text
                    }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
import {
    mdiAccountOutline,
    mdiStorefrontOutline,
    mdiPackageVariantClosed,
    mdiShoppingOutline,
} from "@mdi/js";

export default {
    name: "MenuNavModules",

    props: {
        isUserAdmin: Boolean,
        isUserSeller: Boolean,
    },

    data: () => ({
        items: [
            {
                text: "Pedidos",
                icon: mdiStorefrontOutline,
                pathName: "/orders",
            },
        ],
    }),

    methods: {
        addSellerOptionsToMenu() {
            this.items.unshift({
                text: "Productos",
                icon: mdiPackageVariantClosed,
                pathName: "/products",
            });
            this.items.push({
                text: "Ventas",
                icon: mdiShoppingOutline,
                pathName: "/sales",
            });
        },
        addAdminOptionsToMenu() {
            this.items.unshift({
                text: "Productos - local",
                icon: mdiPackageVariantClosed,
                pathName: "/products",
            });
            this.items.push({
                text: "Ventas",
                icon: mdiShoppingOutline,
                pathName: "/sales",
            });
            this.items.unshift({
                text: "Productos",
                icon: mdiPackageVariantClosed,
                pathName: "/bothProducts",
            });
            this.items.unshift({
                text: "Gestion de costos",
                icon: mdiAccountOutline,
                pathName: "/fares",
            });
            this.items.unshift({
                text: "Mayorista",
                icon: mdiAccountOutline,
                pathName: "/mayorista",
            });
            this.items.unshift({
                text: "Usuarios",
                icon: mdiAccountOutline,
                pathName: "/users",
            });
            this.items.unshift({
                text: "Dashboard",
                icon: "mdi-notebook",
                pathName: "/dashboard",
            });
        },
        /* addSellerOptionsToMenu () {
      this.items.unshift({ text: 'Productos', icon: mdiPackageVariantClosed, pathName: '/products' })
    },
    addAdminOptionsToMenu () {
      this.items.unshift({ text: 'Productos', icon: mdiPackageVariantClosed, pathName: '/products' })
      this.items.unshift({ text: 'Usuarios', icon: mdiAccountOutline, pathName: '/users' })
    }, */
        setDefaultOptionsMenu() {
            this.items = [
                {
                    text: "Pedidos",
                    icon: mdiStorefrontOutline,
                    pathName: "/orders",
                },
            ];
        },
    },
    mounted() {
        this.setDefaultOptionsMenu();
        if (this.isUserAdmin) {
            this.addAdminOptionsToMenu();
        } else if (this.isUserSeller) {
            this.addSellerOptionsToMenu();
        }
    },
    watch: {
        isUserAdmin: {
            handler() {
                if (this.isUserAdmin) {
                    this.addAdminOptionsToMenu();
                }
            },
        },
        isUserSeller: {
            handler() {
                if (this.isUserSeller) {
                    this.addSellerOptionsToMenu();
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped></style>
