<template>
  <div>
    <v-row class="mt-3 mb-3">
      <v-col cols="12">
        <v-card
            elevation="0"
        >
          <v-card-title>Detalle de compra</v-card-title>
          <v-divider></v-divider>
          <v-container>
<!--              <v-row>-->
<!--                <v-col-->
<!--                  cols="12"-->
<!--                  sm="6"-->
<!--                  class="text-left"-->
<!--                  :md="boxInputValues.length > 0 ? '4' : null"-->
<!--                  v-for="(item, i) in boxInputValues"-->
<!--                  :key="i"-->
<!--                >-->
<!--                  <v-card-subtitle style="font-size:12px">{{item.text}}</v-card-subtitle>-->
<!--                  <v-card-text style="font-size:15px">{{itemProp[item.value] ? itemProp[item.value] : itemProp.client[item.value]}}</v-card-text>-->
<!--                </v-col>-->
<!--              </v-row>-->
              <v-row>
                <v-col cols="12"
                       sm="4"
                       class="text-left"
                       md="4">
                  <v-card-subtitle style="font-size:12px">Datos de facturacion</v-card-subtitle>
                  <v-card-text style="font-size:15px">
                    {{itemProp.client.name}} {{itemProp.client.last_name}} <br>
                    {{itemProp.client_address}} <br>
                    {{itemProp.client_postcode}} <br>
                    {{itemProp.client_Province}} <br>
                    {{itemProp.client_city}} <br>
                    {{ (itemProp.client.phone !== undefined && itemProp.client.phone !== '') ? itemProp.client.phone : 'Sin telefono' }} <br>
                    {{itemProp.client.email}}
                  </v-card-text>
                </v-col>
                <v-col cols="12"
                       sm="4"
                       class="text-left"
                       md="4">
                  <v-card-subtitle style="font-size:12px">Datos de envio</v-card-subtitle>
                  <v-card-text style="font-size:15px">
                    {{itemProp.client.name}} {{itemProp.client.last_name}} <br>
                    {{itemProp.client_shipping_address}} <br>
                    {{itemProp.client_shipping_postcode}} <br>
                    {{itemProp.client_shipping_province}} <br>
                    {{itemProp.client_shipping_city}} <br>
                    {{ (itemProp.client.phone !== undefined && itemProp.client.phone !== '') ? itemProp.client.phone : 'Sin telefono' }} <br>
                    {{itemProp.client.email}}
                  </v-card-text>
                </v-col>
                <v-col cols="12"
                       sm="4"
                       class="text-left"
                       md="4">
                  <v-card-subtitle style="font-size:12px">Datos adicionales</v-card-subtitle>
                  <v-card-text style="font-size:15px">
                    Email: {{itemProp.client.email}}
                  </v-card-text>
                </v-col>
              </v-row>
            </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'ViewCardDetails',
  props: {
    boxInputValues: Array,
    itemProp: Object
  }
}
</script>
