<template>
  <v-dialog
    v-model="controlOpenDialog"
    persistent
    max-width="915px"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Estado del pedido
      </v-card-title>

      <v-card-text v-html="getTextByOrderStatus" class="pt-8"></v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="$emit('closeDialog')"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PopupText',
  props: { dialog: Boolean, item: Object },
  data () {
    return {
      text: ''
    }
  },
  computed: {
    controlOpenDialog: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('handleDialog', value)
      }
    },
    getTextByOrderStatus () {
      const idStatus = this.item.order_status?.id || 0
      const completeName = this.item.client.full_name + ' ' + this.item.client.last_name
      if (idStatus === 4) {
        return '<p>Hola, ' + completeName + '</p>' +
          '<p><b>Tu pedido numero ' + this.item.id_external + ' ya está en producción.</b></p>' +
        '<p>¿Qué significa eso? En esta etapa diseñamos y armamos tus etiquetas, las imprimimos y luego revisamos para que todo te llegue perfecto.</p>' +
        '<p>Este proceso puede demorar <b>aproximadamente 10 días hábiles</b>, pero si lo tenemos antes, ¡te avisamos!</p>' +
        '<p>Cuando esté listo para enviar o que lo retires, te haremos llegar un aviso por mail.</p>' +
        '<p>El equipo de Etiquecosas</p>'
      }
      if (idStatus === 5) {
        if (this.item.shipping_line.includes('Retiro oficina') || this.item.shipping_line.includes('Retiro por local')) {
          return '<p>Hola, ' + completeName + '</p>' +
            '<p><b>¡Tu pedido está listo para retirar!</b></p>' +
            '<p>¿Cuándo? De <b>Lunes a Viernes de 12 a 18 hs.</b></p>' +
            '<p>¿Dónde? Por <b>Serrano 394.</b></p>' +
            '<p>¿Qué necesito? <b>Tu número de pedido #' + this.item.id_external + '.</b></p>' +
            '<p>Puede retirar cualquier persona mayor a 18 años incluso pueden coordinar moto de Rappi/PedidosYa que llegan a nuestra zona sin problema.</p>' +
            '<p>¡Muchas gracias por elegirnos!</p>' +
            '<p>El equipo de etiquecosas.</p>'
        }
        return '<p>Hola, ' + completeName + '</p>' +
          '<p><b>¡Tu pedido numero ' + this.item.id_external + ' ya está listo para despachar!</b></p>' +
        '<p>Si el envío que pediste tiene número de seguimiento, te vamos a mandar esa información.</p>' +
        '<p>Si tu pedido va por cadetería, no podemos darte precisión del tiempo y día de entrega porque depende' +
          'del ruteo del cadete. Pero, ¡tranqui que si no estás no pasa nada! Si no te encuentra en el domicilio,' +
          'te llama al celular que dejaste en la compra y coordina sin costo adicional un nuevo envío :)</p>' +
        '<p><b>Pronto estarás recibiendo tu pedido.</b></p>' +
        '<p>¡Muchas gracias por elegirnos!</p>' +
        '<p>El equipo de etiquecosas.</p>'
      }
      if (idStatus === 8) {
        return '<p>Hola, ' + completeName + '</p>' +
          '<p><b>Tu pedido numero ' + this.item.id_external + ' ya casi está listo.</b></p>' +
        '<p>Tenemos una <b>pequeña demora en la entrega</b> porque algunos de los productos que adquiriste no' +
          'salieron bien impresos/troquelados y tenemos que reimprimirlos para que lleguen perfectos a tu casa.</p>' +
        '<p><b>Te pedimos disculpas</b> por este contratiempo y no te preocupes que le vamos a dar prioridad.</p>' +
        '<p><b>Ni bien esté listo, te haremos llegar un aviso por mail.</b></p>' +
        '<p>El equipo de Etiquecosas</p>'
      }
      return ''
    }
  }
}
</script>

<style scoped>

</style>
