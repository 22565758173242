<template>
  <div class="d-flex">
    <v-menu bottom left v-if="itemsMenuList">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="mr-2">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list min-width="200">
        <v-list-item v-for="(item, i) in itemsMenuList" :key="i" @click="selectedItem(i, item)">
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!--<v-btn
      @click="isExpanded ? expand(false) : expand(true)"
      text
      fab
      small
      color="grey darken-1"
      class="btn-arrow-style"
      ><v-icon :class="`fa-arrow-down ${isExpanded && 'open'}`">
        mdi-chevron-down
      </v-icon></v-btn
    >-->
  </div>
</template>

<script>
export default {
  name: 'ColumnExpand',
  props: {
    item: Object,
    isExpanded: Boolean,
    expand: Function,
    selectedMenuItem: Number,
    itemsMenuList: Array
  },
  data: () => ({}),
  methods: {
    selectedItem (val, item) {
      this.$emit('handleSelectedMenuItem', { option: val, item: item, itemPreview: this.item })
    },

    preview () {
    }
  }
}
</script>

<style lang="scss" scoped>
.fa-arrow-down {
  transform: rotate(0deg);
  transition: transform 300ms linear;
}

.fa-arrow-down.open {
  transform: rotate(180deg);
  transition: transform 300ms linear;
}

.btn-arrow-style:focus::before {
  opacity: 0 !important;
}
</style>
